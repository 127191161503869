import { createContext, useReducer, useContext } from 'react';

const StoreContext = createContext();

const initialState = {
    project: 0,
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'actproject':
            return {
                ...state,
                project: action.payload.project,
            };
        default:
            return state;
    }
};

export const StoreProvider = (props) => {
    const [store, dispatch] = useReducer(reducer, initialState);

    const setProject = (project) => {
        dispatch({
            type: 'actproject',
            payload: { project },
        });
    };

    return (
        <StoreContext.Provider
            value={{
                store,
                dispatch,
                setProject,
            }}
            {...props}
        />
    );
};

const useStoreContext = () => useContext(StoreContext);

export { useStoreContext };
