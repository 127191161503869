// import './App.css';
import React from 'react';
import styled from 'styled-components';
import { bannerColor, bannerHeight}  from './style/colors';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import HomeView from './views/Home';
import EditView from './views/Edit';
import MapExampleView from './views/MapExample';
import { StoreProvider } from './contexts/StoreContext'; // put it inside page.js in case you want to share it between components
/* import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom"; */

const HeaderContainer = styled.div`
    height: ${bannerHeight}px ;
    width: 100vw;
    background: ${bannerColor};
    padding-bottom: 1px;
    padding-top: 10px;
    text-align: center;
    z-index: 99;
    font-size: 13pt;
`;

const MenuText = styled.div`
    background: ${bannerColor};
    width: 200px;
    vertical-align: bottom:
    padding-bottom: 1px;
    text-align: left;
    font-size: 14px;
    z-index: 101;
`;




export default function Home() {
    return (
        <StoreProvider>
        <HeaderContainer>WebVeldGis Project Admin module
            <Router>
                    <MenuText>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                        <Link to="/">Home</Link>
                       {/*  &nbsp;&nbsp;&nbsp;&nbsp;
                        <Link to="/edit">Edit</Link>  */}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Link to="/map">Map</Link>
                    </MenuText>

                    {/* A <Routes> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                    <Routes>
                        <Route
                            exact
                            path="/"
                            element={<HomeView />}
                        />
                         {/* <Route
                            exact
                            path="/edit"
                            element={<EditView />}
                        />  */}
                        <Route
                            exact
                            path="/map"
                            element={<MapExampleView />}
                        />
                        </Routes>
            </Router>
            
        </HeaderContainer>
        </StoreProvider>
    );
}
