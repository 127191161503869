// export { alpha, lighten } from '@mui/material';

export const headerColor = '#40BF86';
export const textColor = '#0B2157';
export const defaultBlack = '#333';
export const defaultBlue = '#39F';

export const bannerColor = '#40BF86'; 
export const bannerHeight = 50; 
export const paddingLeft = 0; 

export const buttonHoverColor = '#CCCCFF'; // '#E9E9ED'; // 'gold';
export const buttonHoverTextColor = '#800dad';
export const buttonColor = '#4466FF'; // 'blue'; //  'lightblue';
export const buttonTextColor = 'yellow'; // headerColor

export const darkBlue = '#0B2157';

export const backgroundColor = '#031875';
export const backgroundGrey = '#FFFFFF'; // '#F2F2F2';
export const borderGrey = '#E0E0E0';

export const textColorLightened = '#CCC';

export const whiteText = '#F5F5F7';

export const locationIconColor = defaultBlue;
export const locationIconHighlightColor = '#FFFF00';
export const locationIconTextColor = '#000';
