export function getProjecten() {
    return new Promise((resolve, reject) => {
        fetch('/webveldgisserver/json/projecten')
            .then((response) => response.json())
            .then((data) => resolve(data.items))
            .catch(reject);
    });
}

export function getMapLayers() {
    return new Promise((resolve, reject) => {
        fetch('/webveldgisserver/json/maplayer')
            .then((response) => response.json())
            .then((data) => resolve(data.items))
            .catch(reject);
    });
}


export function saveProject2Server(project) {
    return new Promise((resolve, reject) => {
        fetch(
            `/webveldgisserver/saveproject?project_code=${project.project_code}&project_titel=${project.project_titel}&schaal=${project.schaal}&startdatum=${project.startdatum}&einddatum=${project.einddatum}&uitvoerder=${project.uitvoerder}&opdrachtgever=${project.opdrachtgever}&embargo_datum=${project.embargo_datum}&boor_beschrijving=${project.boor_beschrijving}&bepaling_maaiveldhoogte=${project.bepaling_maaiveldhoogte}&bepaling_locatie=${project.bepaling_locatie}&kaderinwinning=${project.kaderinwinning}&type_boring=${project.type_boring}`
        )
            .then((response) => response.json())
            .then((data) => resolve(data))
            .catch(reject);
    });
}


