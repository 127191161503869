import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getMapLayers } from '../../services/backend';
import { backgroundGrey, bannerColor, bannerHeight } from '../../style/colors';
import OlMap from '../OpenLayers/Map';
import { osm, getWMTS, tileWMS } from '../OpenLayers/Source';
import { Layers, TileLayer } from '../OpenLayers/Layers';
import {
    Controls,
    MousePositionControl,
    ScaleLineControl,
    ZoomControl,
    FullScreenControl,
} from '../OpenLayers/Controls';
// import OnClickEvent from '../OpenLayers/Interaction/OnClick';
import {
    center,
    initialZoom,
    defaultMapProjection,
    minZoom,
    maxZoom,
} from '../OpenLayers/config';


const ContentContainer = styled.div`
    background: ${bannerColor};
    padding-top: 10px;
    width: 100vw;
    height: calc(100vh - ${bannerHeight}px - 12px);
`;

const StyledMap = styled(OlMap)`
width: 100vw;
height: calc(100vh - ${bannerHeight}px - 12px);
    background-color: ${backgroundGrey};

    & .ol-full-screen {
        top: 8px;
        right: 8px;
    }

    & .ol-mouse-position {
        position: absolute;
        bottom: 8px;
        left: 8[x];
        background-color: rgba(255, 255, 255, 0.4);
        border-radius: 4px;
        padding: 2px;
    }
`;

const ExampleMap = () => {
    const [availableLayers, setAvailableLayers] = useState([]);
    // not used: const [visibleLayers, setVisibleLayers] = useState([]);

    // const activeLayer = visibleLayers.length
    //     ? availableLayers.find(
    //           (layer) =>
    //               layer.layerid === visibleLayers[visibleLayers.length - 1]
    //       )
    //     : null;
    useEffect(() => {
        getMapLayers().then((data) => {
            Promise.all(
                data.map(async (layer) => {
                    let source;
                    if (layer.protocol === 'wmts') {
                        source = await getWMTS(
                            layer.onlineresource,
                            layer.layername
                        ).catch((error) => {
                            console.error(error);
                        });
                    } else {
                        source = tileWMS({
                            url: layer.onlineresource,
                            params: { layers: layer.layername },
                        });
                    }
                    return {
                        ...layer,
                        source,
                    };
                })
            ).then((initializedLayers) => {
                setAvailableLayers(initializedLayers);
                // setVisibleLayers([1, 2, 3]); // layerids of layers to show, replaced by check for layer.visible > 0 below
            });
        });
    }, []);

    return (
        <ContentContainer>
            <StyledMap
                center={center}
                zoom={initialZoom}
                minZoom={minZoom}
                maxZoom={maxZoom}
                // dimensions={mapDimensions}
                projection={defaultMapProjection}
            >
                <Layers>
                    {/* background layer: */}
                    <TileLayer source={osm()} zIndex={0} />

                    {/* visible layers: */}
                    {availableLayers
                        .filter(
                            (layer) =>
                                // visibleLayers.includes(layer.layerid)   // replaced by:
                                layer.visible > 0
                        )
                        .map((layer) => {
                            return (
                                <TileLayer
                                    key={layer.layerid}
                                    // extent={layer.extent}
                                    source={layer.source}
                                    // zIndex={layer.orderGroup}
                                    zIndex={1}
                                    // opacity={layerState[layer.layerid]?.opacity}
                                />
                            );
                        })}
                </Layers>

                <Controls>
                    <FullScreenControl />
                    <MousePositionControl />
                    <ScaleLineControl />
                    <ZoomControl />
                </Controls>

                {/* user interactions / events: */}
                {/* {activeLayer && (
                <OnClickEvent
                    layerSource={activeLayer.source}
                    layerName={activeLayer.name}
                    layerFeatureInfoUrl={activeLayer.featureinfourl}
                    onClickFunc={(...args) => console.log(args)}
                    getFeatureInfo={true}
                    // onClickStart={() => {
                    //     setShowLoadingCursor(true);
                    // }}
                    // onClickFinal={() => {
                    //     setShowLoadingCursor(false);
                    // }}
                />
            )} */}
            </StyledMap>
        </ContentContainer>
    );
};

export default ExampleMap;
