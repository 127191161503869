import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getProjecten, saveProject2Server } from '../services/backend';
import {
    paddingLeft,
    backgroundGrey,
    textColor
} from '../style/colors';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useStoreContext } from '../contexts/StoreContext';

const ContentContainer = styled.div`
    padding-left: ${paddingLeft}px;
    background: ${backgroundGrey};
`;

export const VerticalContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
    flex-wrap: wrap;
`;

export const HorizontalContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    flex-wrap: wrap;
    font-size: 14px;
    padding-bottom: 5px;
`;

export const LeftContainer = styled.div`
width: 350px;
height: 100hw;
float: left;
// padding-top: 20px;
padding-left: 20px;
text-align: left;
font-size: 24px;
font-weight: bold;
z-index: 2;
`;

export const RightContainer = styled.div`
height: 100hw;
float: left;
padding-left: 20px;
text-align: left;
font-size: 24px;
font-weight: bold;
z-index: 2;
`;

export const ProjectContainer = styled.div`
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
`;

export const NewLineText = styled.div`
    width: 40px;
    clear: both;
    color: ${textColor};
    font-size: 20px;
    padding-left: 5px;
    padding-right: 5px;
`;


const FormLabel = styled.label`
    padding-right: 30px;
    width: 150px;
`;

export const ComboSelect = styled.select`
    width: 205px;
    background: WHITE;
`;

const InputText = styled.input`
width: 180px;
`
const InputNumber = styled.input`
width: 180px;
`


const Home = () => {
    const [projecten, setProjecten] = useState([]);
    // not used: const [visibleLayers, setVisibleLayers] = useState([]);

    
    const { store, dispatch, setProject } = useStoreContext();

    // const [selectedProjectCode, setSelectedProjectCode] = useState(store.project.project_code); // store.project

    const handleSetProject = (project) => {
        // StoreContext global state, indirect dispatch via Action creators function setProjectCode

        // or instead of dispatch: setProject(project)
        dispatch({
            type: 'actproject',
            payload: { project },
        });
        document.getElementById('editpanel').hidden=false;
    };

    const changeProject = (project) => {
        document.getElementById('projecten').hidden=true;

        document.getElementById('but1').hidden=false;
        document.getElementById('but2').hidden=false;
        handleSetProject(project);
    };

    const changeProjectCode = (value) => {
        store.project.project_code = value;
        changeProject(store.project);
    };
    
    const changeTitel = (value) => {
        store.project.project_titel = value;
        changeProject(store.project);
    };

    const changeOpdrachtgever = (value) => {
        store.project.opdrachtgever = value;
        changeProject(store.project);
    };

    const changeSchaal = (value) => {
        store.project.schaal = value;
        changeProject(store.project);
    };

    const changeUitvoerder = (value) => {
        store.project.uitvoerder = value;
        changeProject(store.project);
    };
    
    const changeBoorbeschrijving
    = (value) => {
        store.project.boor_beschrijving = value;
        changeProject(store.project);
    };
    
    const changeHoogtebepaling = (value) => {
        store.project.bepaling_maaiveldhoogte = value;
        changeProject(store.project);
    };
    
    const changeLocatiebepaling = (value) => {
        store.project.bepaling_locatie = value;
        changeProject(store.project);
    };
    
    const changeKaderinwinning = (value) => {
        store.project.kaderinwinning = value;
        changeProject(store.project);
    };
    
    const changeTypeBoring = (value) => {
        store.project.type_boring = value;
        changeProject(store.project);
    };
    
    const changeStartdatum = (value) => {
        store.project.startdatum = (value==null || value==undefined ? null : value.getFullYear() + '-' + (value.getMonth()+1) + '-' + value.getDate() );
        changeProject(store.project);
    };
    
    const changeEinddatum = (value) => {
        store.project.einddatum = (value==null || value==undefined ? null : value.getFullYear() + '-' + (value.getMonth()+1) + '-' + value.getDate() );
        changeProject(store.project);
    };
    
    const changeEmbargodatum = (value) => {
        store.project.embargo_datum = (value==null || value==undefined ? null : value.getFullYear() + '-' + (value.getMonth()+1) + '-' + value.getDate() );
        changeProject(store.project);
    };
    
    const cancel = () => {
        document.getElementById('projecten').hidden=false;
        document.getElementById('but1').hidden=true;
        document.getElementById('but2').hidden=true;
        getProjecten().then((data) => {
            setProjecten(data);
            setProject(data[0]); 
        });
        // store.project = null;
    };
    
    const saveProject = () => {
        if(store.project.project_code > 0 ) { 
            saveProject2Server(store.project).then((data) => {
                if(data.success) {
                    document.getElementById('projecten').hidden=false;
                    document.getElementById('but1').hidden=true;
                    document.getElementById('but2').hidden=true;
                    getProjecten().then((data) => {
                        setProjecten(data);
                        setProject(data[0]); 
                    })
                }
            });
         }
        else { alert("Project code moet groter zijn dan 0"); }
    };

    useEffect(() => {
        getProjecten().then((data) => {
            setProjecten(data);
            
        });
    }, []);

    return (
        <ContentContainer>
        <LeftContainer>
          <NewLineText>&nbsp;</NewLineText>
                    <label>Selecteer een Project: </label>
          <NewLineText>&nbsp;</NewLineText>
          
          <ProjectContainer id="projecten">
                    {projecten.map((project) => {
                   if (project.existing===0) return (
                    <fragment><div id={project.project_code} onClick={() => handleSetProject(project)}>Nieuw project</div><div><br /></div></fragment>);
                else return (  
                         <div id={project.project_code} onClick={() => handleSetProject(project)}>{project.project_code} {project.project_titel}  </div>)
                })}
        </ProjectContainer>
        
        </LeftContainer>
        <RightContainer id="editpanel" hidden="true">
          <NewLineText>&nbsp;</NewLineText>
                    <label>Edit Project {store.project.project_code}: {store.project.project_titel}</label>
          <NewLineText>&nbsp;</NewLineText>
          <VerticalContainer>
            <HorizontalContainer>
                <FormLabel hidden={store.project.existing}>Project code</FormLabel>
                <InputNumber type="number" min="0" value={store.project.project_code} hidden={store.project.existing} 
                    onChange={(input) =>
                        changeProjectCode(input.target.value)
                    }/>
            </HorizontalContainer> 
            <NewLineText>&nbsp;</NewLineText>
            <HorizontalContainer>
                <FormLabel>Titel</FormLabel>
                <InputText value={store.project.project_titel} 
                    onChange={(input) =>
                        changeTitel(input.target.value)
                    }/>
            </HorizontalContainer> 
            <HorizontalContainer>
                <FormLabel>Opdrachtgever</FormLabel>
                <InputText value={store.project.opdrachtgever} 
                    onChange={(input) =>
                        changeOpdrachtgever(input.target.value)
                    }/>
            </HorizontalContainer>  
            <HorizontalContainer>
                <FormLabel>Startdatum</FormLabel>
                    <DatePicker
                        selected={store.project.startdatum}
                        onChange={(date) => changeStartdatum(date)}
                        dateFormat="yyyy-MM-dd"
                    />
            </HorizontalContainer>
            <HorizontalContainer>
                <FormLabel>Einddatum</FormLabel>
                    <DatePicker
                        selected={store.project.einddatum}
                        onChange={(date) => changeEinddatum(date)}
                        dateFormat="yyyy-MM-dd"
                        minDate={store.project.startdatum}
                    />
            </HorizontalContainer>
            <HorizontalContainer>
                <FormLabel>Embargo datum</FormLabel>
                    <DatePicker
                        selected={store.project.embargo_datum}
                        onChange={(date) => changeEmbargodatum(date)}
                        dateFormat="yyyy-MM-dd"
                    />
            </HorizontalContainer> 
            <HorizontalContainer>
                <FormLabel>Schaal</FormLabel>
                <InputNumber type="number" min="0" value={store.project.schaal} 
                    onChange={(input) =>
                        changeSchaal(input.target.value)
                    }/>
            </HorizontalContainer> 
            <HorizontalContainer>
                <FormLabel>Uitvoerder</FormLabel>
                <InputText value={store.project.uitvoerder} 
                    onChange={(input) =>
                        changeUitvoerder(input.target.value)
                    }/>
            </HorizontalContainer> 
            <HorizontalContainer>
                <FormLabel>Type boring</FormLabel>                    
                    <ComboSelect
                        name="typeboring"
                        id="typeboring"
                        value={store.project.type_boring} 
                        onChange={(option) => changeTypeBoring(option.target.value)}>
                        <option value=" "></option>
                        <option value="Boorpofiel">Boorpofiel</option>
                        <option value="Humusprofiel">Humusprofiel</option>
                        <option value="Hydrofysische bemonstering">Hydrofysische bemonstering</option>
                        <option value="Locatie informatie">Locatie informatie</option>
                        <option value="Wandprofiel">Wandprofiel</option>
                    </ComboSelect>
            </HorizontalContainer> 
            <HorizontalContainer>
                <FormLabel>Kader inwinning</FormLabel>                    
                    <ComboSelect
                        name="kaderinwinning"
                        id="kaderinwinning"
                        value={store.project.kaderinwinning} 
                        onChange={(option) => changeKaderinwinning(option.target.value)}>
                        <option value=" "></option>
                        <option value="bodemkaartNederland">bodemkaartNederland</option>
                        <option value="gebiedsinrichting">gebiedsinrichting</option>
                        <option value="hydrologischOnderzoek">hydrologischOnderzoek</option>
                        <option value="bodemopbouwNatuurterreinen">bodemopbouwNatuurterreinen</option>
                        <option value="bodemfysischOnderzoek">bodemfysischOnderzoek</option>
                        <option value="bodemchemischOnderzoek">bodemchemischOnderzoek</option>
                        <option value="delfstofwinning">delfstofwinning</option>
                        <option value="educatie">educatie</option>
                    </ComboSelect>
            </HorizontalContainer> 
            <HorizontalContainer>
                <FormLabel>Boorbeschrijving</FormLabel>
                    <ComboSelect
                        name="boor_beschrijving"
                        id="boor_beschrijving"
                        value={store.project.boor_beschrijving} 
                        onChange={(option) => changeBoorbeschrijving(option.target.value)}>
                        <option value=" "> </option>
                        <option value="HBGO_DEELA">HBGO_DEELA</option>
                        <option value="HBGO_DEELA_HP">HBGO_DEELA_HP</option>
                    </ComboSelect>
            </HorizontalContainer> 
            <HorizontalContainer>
                <FormLabel>Hoogtebepaling</FormLabel>
                    <ComboSelect
                        name="bepaling_maaiveldhoogte"
                        id="bepaling_maaiveldhoogte"
                        value={store.project.bepaling_maaiveldhoogte} 
                        onChange={(option) => changeHoogtebepaling(option.target.value)}>
                        <option value=" "> </option>
                        <option value="AHN2023-5X5">AHN2023-5X5</option>
                        <option value="AHN2-5X5">AHN2-5X5</option>
                        <option value="AHN2001-5X5">AHN2001-5X5</option>
                        <option value="RTKGPS0tot4cm">RTKGPS0tot4cm</option>
                        <option value="RTKGPS4tot10cm">RTKGPS4tot10cm</option>
                        <option value="RTKGPS10tot20cm">RTKGPS10tot20cm</option>
                        <option value="RTKGPS20tot100cm">RTKGPS20tot100cm</option>
                    </ComboSelect>
            </HorizontalContainer> 
            <HorizontalContainer>
                <FormLabel>Locatiebepaling</FormLabel>
                    <ComboSelect
                        name="bepaling_locatie"
                        id="bepaling_locatie"
                        value={store.project.bepaling_locatie} 
                        onChange={(option) => changeLocatiebepaling(option.target.value)}>
                        <option value=" "> </option>
                        <option value="LGPSN">LGPSN</option>
                        <option value="LT5">LT5</option>
                        <option value="LT10">LT10</option>
                        <option value="LT25">LT25</option>
                    </ComboSelect>
            </HorizontalContainer> 
            <NewLineText>&nbsp;</NewLineText>
            <HorizontalContainer>
                <FormLabel><input type="button" id="but1" value="Annuleren" hidden="true"style={{width: "125px", height: "30px"}}
                    onClick={() =>
                        cancel()
                    }/></FormLabel>
                <input type="button" id="but2" value="Opslaan" hidden="true" style={{width: "150px", height: "30px"}}
                    onClick={() =>
                        saveProject()
                    }/>
            </HorizontalContainer> 
          </VerticalContainer>
          </RightContainer>

        </ContentContainer>
    );
};

export default Home;