import { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import { getProjecten } from '../services/backend';
import {
    paddingLeft,
    textColor
} from '../style/colors';

import { useStoreContext } from '../contexts/StoreContext';

const ContentContainer = styled.div`
    padding-left: ${paddingLeft}px;
    width: 100vw;
    height: 100hw;
`;

export const HorizontalContainer = styled.div`
// padding-top: 20px;
padding-left: 20px;
text-align: left;
font-size: 24px;
font-weight: bold;
z-index: 2;
width: 100vw;
height: 100hw;
`;

export const ProjectContainer = styled.div`
    width: 100vw;
    padding-left: 20px;
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
`;

export const NewLineText = styled.div`
    width: 40px;
    clear: both;
    color: ${textColor};
    font-size: 20px;
    padding-left: 5px;
    padding-right: 5px;
`;

const Edit = () => {
    const [projecten, setProjecten] = useState([]);

    const { store, dispatch, setProject } = useStoreContext();

    // const [selectedProjectCode, setSelectedProjectCode] = useState(store.projectcode); // store.projectcode

    // const handleSetProjectCode = (projectcode) => {
    //     // DownloadContext global state, indirect dispatch via Action creators function setProjectCode
    //     useStoreContext.setProjectCode(projectcode);
    //     setSelectedProjectCode(projectcode);
    // };
    
    useEffect(() => {
        getProjecten().then((data) => {
            setProjecten(data);
        });
    }, []);

    return (
        <ContentContainer>
          <NewLineText>&nbsp;</NewLineText>
            <HorizontalContainer>
                    <label>Edit Project {store.project.project_code}: {store.project.project_titel} </label>
            </HorizontalContainer>
        </ContentContainer>
    );
};

export default Edit;
